import { Injectable } from '@angular/core';
import {FirebaseConfig} from "../interface/firbase-config";

@Injectable({
  providedIn: 'root'
})
export  class EnvService {

    //API_URL = 'http://localhost/agacare.io/backend-aga/public/api/';
    //API_URL = 'http://localhost/aga/backend-aga/public/api/';

     static ACCOUNT_SID = 'AC90a7f2d549ba9cfee0788986fd763597';
     //API_URL = 'https://testlanding.agacare.io/api/';
     API_URL = 'https://backend.agacare.io/api/'
    //static DOMAIN_URL = 'https://myaga.agacare.io/';
     static DOMAIN_URL = 'http://localhost:4200/';
     //static DOMAIN_URL = 'https://testmyaga.agacare.io/';
      static fireBaseConfig: FirebaseConfig = {
                                                              apiKey: "AIzaSyDO9G2FIGtRGit5U-iQlYVho587Nrmmspk",
                                                              authDomain: "agacate-34f75.firebaseapp.com",
                                                              projectId: "agacate-34f75",
                                                              storageBucket: "agacate-34f75.appspot.com",
                                                              messagingSenderId: "781561863500",
                                                              appId: "1:781561863500:web:34500776141644a66d83e9",
                                                              measurementId: "G-FN998EJJTW"
                                                          };

    constructor() {

    }
}
